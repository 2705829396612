import { Routes } from '@models/routes';
import { lazy } from 'react';

const Solar = lazy(() => import('@pages/solar/index').then((p) => ({ default: p.Solar })));

export const SolarRoutes: Routes = [
  {
    path: '/solar',
    component: Solar
  }
];
