import { Routes } from '@models/routes';
import { lazy } from 'react';

const Recruitment = lazy(() => import('@pages/recruitment/index').then((p) => ({ default: p.Recruitment })));

export const RecruitmentRoutes: Routes = [
  {
    path: '/recruitment',
    component: Recruitment,
    children: [
      {
        path: ':type',
        component: Recruitment
      }
    ]
  }
];
