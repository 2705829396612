import { Box, CssBaseline, Fab, makeStyles, Toolbar, useScrollTrigger, Zoom } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';
import { Layout, LayoutProps } from '@models/layout';
import React, { ReactElement } from 'react';
import { MainFooter } from './MainFooter';
import { MainNavMenu } from './MainNavMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

export function ScrollTop(props: { children: JSX.Element }) {
  const { children } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window : undefined,
    disableHysteresis: true,
    threshold: 100
  });

  const handleClick = (event: React.MouseEvent) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  React.useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role='presentation' className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

export const MainLayout: Layout = ({ children }: LayoutProps): ReactElement => {
  return (
    <Box className='flex flex-col min-h-full w-full overflow-auto justify-center items-center mx-auto'>
      <CssBaseline />
      <MainNavMenu />
      <Toolbar id='back-to-top-anchor' />
      <Box flexGrow={1} className='flex m-auto w-full justify-center xs:mb-16 bg-white' minHeight='82vh'>
        <Box width='100%' height='100%' mt={4.5}>
          {children}
        </Box>
      </Box>
      <MainFooter />
      <ScrollTop>
        <Fab color='primary' size='small' aria-label='scroll back to top'>
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
    </Box>
  );
};
