import { EPaymentType } from '@models/insurance';
import { AppStateType, initialState } from '@states/AppState';
import React from 'react';

export type IAppState = {
  paymentType: EPaymentType;
};

export type ContextProps = {
  state: IAppState;
};

export const AppContext = React.createContext<AppStateType>({
  appState: initialState,
});

export const useAppContext = (): AppStateType => React.useContext(AppContext);
