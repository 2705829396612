import React, { ReactElement } from 'react';
import { Routes as RoutesType, Route as RouteType } from '@models/routes';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { flattenRoutes } from '@utils/routes';
import { InsuranceRoutes } from './Insurance';
import { MainLayout } from '@layouts/MainLayout';
import { SolarRoutes } from './Solar';
import { RecruitmentRoutes } from './Recruitment';

const routes: RoutesType = flattenRoutes([...InsuranceRoutes, ...SolarRoutes, ...RecruitmentRoutes]);

const AppRoute = ({ component: Component }: RouteType): ReactElement => {
  return (
    <React.Suspense fallback={null}>
      <MainLayout>
        <Component />
      </MainLayout>
    </React.Suspense>
  );
};

export const AppRoutes = (): React.ReactElement => (
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Navigate to='/insurance' replace />} />
      <Route path='/recruitment' element={<Navigate to='/recruitment/marketing-assistant' replace />} />
      {routes.map((r) => (
        <Route path={r.path} key={r.path} element={<AppRoute component={r.component} path={r.path} />} />
      ))}
    </Routes>
  </BrowserRouter>
);
