import { useAppState } from '@states/AppState';
import React from 'react';
import { AppContext } from './AppContext';

export const AppProvider = ({ children }: { children: JSX.Element }) => {
  const { appState, setPaymentType } = useAppState();
  const value = React.useMemo(
    () => ({
      appState,
      setPaymentType,
    }),
    [setPaymentType, appState]
  );
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
