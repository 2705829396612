import { Routes } from '@models/routes';
import { lazy } from 'react';

const Insurance = lazy(() =>
  import('@pages/insurance/index').then((p) => ({ default: p.Insurance }))
);

export const InsuranceRoutes: Routes = [
  {
    path: '/insurance',
    component: Insurance,
  },
];
