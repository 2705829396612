import { i18n } from '@lingui/core';
import { en } from 'make-plural/plurals';

export enum LOCALES {
  'en' = 'en',
}
export const defaultLocale = LOCALES.en;

i18n.loadLocaleData({
  en: { plurals: en },
});

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string): Promise<void> {
  const { messages } = await import(`./locales/${locale}`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}
