import { t } from '@lingui/macro';
import { EPaymentType } from '@models/insurance';

export const paymentPrice = {
  [EPaymentType.MONTHLY]: '€15.50',
  [EPaymentType.ANNUAL]: '€174.50'
};

export const boostData = [
  {
    title: 'High Valuable Belongings',
    src: '/assets/images/insurance/img_highvalue.png',
    subTitle: [
      'This cover is for items worth more than €1000',
      'This is commonly referred to as specified all risks cover. This will cover personal belongings both inside and outside the home where each item has a value of more than €1000. For example jewellery, digital cameras, laptops, sports equipment etc.'
    ]
  },
  {
    title: 'Personal Belongings',
    src: '/assets/images/insurance/img_project.png',
    subTitle: [
      'This cover is for items worth less than €1000',
      'This is commonly referred to as unspecified all risks cover. It is the total amount of cover required for items inside and outside the home at any one time. There is a limit per item of €1000.'
    ]
  },
  {
    title: 'Personal Accident Cover',
    src: '/assets/images/insurance/img_sheild.png',
    subTitle: [
      'This is additional cover for you, your spouse / partner and children if they are under 18 and normally living in the home. This covers bodily injuries arising from an accident within your home up to €6,500.'
    ]
  }
];

export const coverIncludeData = [
  {
    title: t`Emergency Home Assistance`,
    img: '/assets/images/insurance/img_sos.png',
    data: [
      '€500 for emergency house roof repairs',
      '€250 for boiler, water, electrical, heating or similar emergency repairs',
      'No excess up to these limits and no impact on your no-claims-discount (up to four call-outs per year)',
      'If your house is uninhabitable you will also get up to 100 for hotel or travel costs'
    ]
  },
  {
    title: t`Home Repair Network`,
    subTitle: [
      t`If you need an emergency repair, we will dispatch a qualified expert from our nationwide panel of experienced, professional builders and tradesmen.`
    ],
    img: '/assets/images/insurance/img_repair.png',
    data: ['Handpicked and vetted by us', 'Repairs are fully guaranteed']
  }
];

export const monthlyBill = [
  {
    title: 'Initial premium',
    subTitle: 'includes Personal Belongings cover',
    price: '€184.77'
  },
  {
    title: 'Web discount',
    price: '€-13.60'
  },
  {
    title: 'Security & Safety Combination discount',
    price: '€-4.77'
  },
  {
    title: 'Premium (excluding Government Levy)',
    price: '€99.00'
  },
  {
    title: 'Government Levy',
    price: '€5.00'
  },
  {
    title: 'Stamp Duty',
    price: '€1.00'
  }
];

export const annualBill = [
  {
    title: 'Initial premium',
    subTitle: 'includes Personal Belongings cover',
    price: '€172.77'
  },
  {
    title: 'Web discount',
    price: '€-13.50'
  },
  {
    title: 'Security & Safety Combination discount',
    price: '€-4.77'
  },
  {
    title: 'Premium (excluding Government Levy)',
    price: '€99.00'
  },
  {
    title: 'Government Levy',
    price: '€5.00'
  },
  {
    title: 'Stamp Duty',
    price: '€1.00'
  }
];

export const paymentOptionData = [
  {
    type: EPaymentType.ANNUAL,
    title: 'Annual / Pay in full',
    price: '€174.50',
    unselectText: 'Select Annual',
    selectedText: 'Selected'
  },
  {
    type: EPaymentType.MONTHLY,
    title: 'Monthly / Pay monthly',
    monthlyNumber: 11,
    price: '€15.50',
    depositPrice: '€15.50',
    unselectText: 'Select Monthly',
    selectedText: 'Selected'
  }
];
