import React from 'react';
import { Box, Hidden, Typography } from '@material-ui/core';
import { colors } from '@theme';
import { Trans } from '@lingui/macro';

export const MainFooter = () => {
  return (
    <Box className={'mt-auto py-4 w-full'} height={124} bgcolor={colors.grey[900]}>
      <Box
        display='flex'
        justifyContent='space-between'
        width='80%'
        mx='auto'
        alignItems='center'
        height='100%'
        maxWidth={1290}
        flexWrap='wrap'
      >
        <Box height={36} display='flex' alignItems='center'>
          <img alt='' src={'/assets/images/logo_pipelyne_white.svg'} className='h-full mr-4' />
          <Hidden smDown>
            <Typography className='whiteText' variant='subtitle1'>
              <Trans>© Pipelyne all rights reserved</Trans>
            </Typography>
          </Hidden>
        </Box>
        <Box display='flex'>
          <Box width={24} height={24}>
            <img src='/assets/images/instagram_logo.svg'></img>
          </Box>
          <Box width={24} height={24} ml={4}>
            <img src='/assets/images/twitter_logo.svg'></img>
          </Box>
          <Box width={24} height={24} ml={4}>
            <img src='/assets/images/facebook_logo.svg'></img>
          </Box>
        </Box>
        <Hidden mdUp>
          <Box width={'100%'} textAlign='center'>
            <Typography className='whiteText' variant='subtitle1'>
              <Trans>© Pipelyne all rights reserved</Trans>
            </Typography>
          </Box>
        </Hidden>
      </Box>
    </Box>
  );
};
