import { IAppState } from '@contexts/AppContext';
import { EPaymentType } from '@models/insurance';
import React from 'react';

export const initialState: IAppState = {
  paymentType: EPaymentType.ANNUAL
};

type Action = { type: 'SET_PAYMENT'; paymentType: EPaymentType };

export const reducer = (state: IAppState, action: Action): IAppState => {
  switch (action.type) {
    case 'SET_PAYMENT':
      return { ...state, paymentType: action.paymentType };
    default:
      return state;
  }
};

const setPaymentType = (dispatch: React.Dispatch<Action>) => (paymentType: EPaymentType) =>
  dispatch({ type: 'SET_PAYMENT', paymentType });

export interface AppStateType {
  appState: IAppState;
  setPaymentType?: (paymentType: EPaymentType) => void;
}

export const useAppState = (): AppStateType => {
  const [appState, dispatch] = React.useReducer(reducer, initialState);
  return {
    setPaymentType: React.useCallback((paymentType) => setPaymentType(dispatch)(paymentType), []),
    appState
  };
};
