import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import React from 'react';
import { defaultLocale, dynamicActivate } from './i18n';
import { ThemeProvider } from '@material-ui/core';
import { BaseMuiTheme } from '@theme';
import { AppRoutes } from './routers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AppProvider } from '@contexts/AppProvider';

function App() {
  React.useEffect(() => {
    // With this method we dynamically load the catalogs
    dynamicActivate(defaultLocale);
  }, []);
  console.log;
  return (
    <ThemeProvider theme={BaseMuiTheme}>
      <I18nProvider i18n={i18n}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AppProvider>
            <AppRoutes />
          </AppProvider>
        </MuiPickersUtilsProvider>
      </I18nProvider>
    </ThemeProvider>
  );
}

export default App;
