import { useAppContext } from '@contexts/AppContext';
import { paymentPrice } from '@datas/insurance';
import { AppBar, Box, Button, Toolbar } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

export const MainNavMenu = (): ReactElement => {
  const {
    appState: { paymentType }
  } = useAppContext();

  const location = useLocation();
  const { pathname } = location;

  return (
    <AppBar className='bg-white'>
      <Toolbar>
        <Box
          display='flex'
          justifyContent='space-between'
          width='100%'
          mx='auto'
          alignItems='center'
          height={100}
          maxWidth={1290}
        >
          <Box height={36}>
            <img alt='' src={'/assets/images/logo_pipelyne.svg'} className='h-full' />
          </Box>
          <Box minWidth={{ xs: 146, md: 307 }}>
            <Button fullWidth variant='contained' color='primary'>
              {pathname === '/insurance' && `Pay ${paymentPrice[paymentType]} / ${paymentType}`}
              {pathname === '/solar' && `Book a call`}
              {pathname.includes('/recruitment') && `Apply`}
            </Button>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
